window.onload = (function () {
    if (
        window.innerWidth < 901 &&
        document.querySelector('.content__title--mobile-top')
    ) {
        const goodsName = document.querySelector('h1.content__title--mobile-top')
        goodsName.remove()
        const container = document.querySelector('.content.content--pt')
        container.prepend(goodsName)
    }
})